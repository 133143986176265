import Vue from 'vue'

const AccountService = {

  registerAdm(n){
    return Vue.prototype.$http
          .post('/api/services/app/Account/RegisterAdm', n)
  }
}

export default AccountService
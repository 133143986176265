export default {
    listEnum: [
        {
            text: 'Administrador',
            value: 'ADMIN',
        },
        {
            text: 'Marketing',
            value: 'MARKETING',
        },
        {
            text: 'Investidor',
            value: 'INVESTOR',
        },
        {
            text: 'Construtora',
            value: 'COMPANY',
        },
        {
            text: 'Corretor',
            value: 'BROKER',
        },
        {
            text: 'Pré-Corretor',
            value: 'PRE_BROKER',
        },
    ]
  }
    
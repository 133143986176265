<template>
  <div>
    <Loader v-if="loading"/>
    <div>
        <b-button variant="success" @click="showModal()" style="margin-right: 1em">Criar Usuário</b-button>
        <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <b-row>
            <b-col cols="2">
               <b-form-group label="Nome" label-for="Nome">
                  <b-form-input v-model="filters.nameFilter"/>
               </b-form-group>
            </b-col>
              <b-col cols="2">
                  <b-form-group label="Tipo" label-for="status-cota">
                      <b-form-select
                          id="status-cota"
                          v-model="filters.tipoFilter"
                          :options="listTipo"/>
                  </b-form-group>
              </b-col> 
            <b-col cols="2">
               <b-form-group label="Telefone" label-for="Telefone">
                  <b-form-input v-model="filters.phoneNumberFilter" v-mask="['(##) #####-####']"/>
               </b-form-group>
            </b-col>     
              <b-col cols="2">
                  <b-form-group label="Status" label-for="status-cota">
                      <b-form-select
                          id="status-cota"
                          v-model="filters.statusFilter"
                          :options="listStatus"/>
                  </b-form-group>
              </b-col> 
            </b-row>
            <b-row>
              <div class="aling-buttons button-left">
                <b-button variant="primary" @click="clear()">Limpar</b-button>  
              </div>
              <div class="aling-buttons">
                <b-button variant="primary" @click="getList()">Pesquisar</b-button>  
              </div>
            </b-row>
          </b-card>
        </b-collapse>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Procurar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Procurar"
              type="text"
              class="d-inline-block" />
          </div>
        </b-form-group>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      id="table_users"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'isActive'">
          <b-badge :variant="statusVariant(props.row.isActive)">
            {{ props.row.isActive }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link
              v-b-tooltip.hover.v-secondary
              title="Editar conta"
              :to="{
              name: 'editing-user-data',
              params: { usuario: props.row },
            }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
          <!--b-link
              v-b-tooltip.hover.v-secondary
              title="Excluir Usuário"
              @click="confirmDeleteUser(props.row)">
            <feather-icon icon="TrashIcon" class="mr-50" />
          </b-link -->
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-alert-signatario" centered @ok="deleteUser()">Confirma a exclusão do usuário {{userDelete.name}}</b-modal>
    <b-modal id="modalCreate"  ref="modalCreate" size="lg"> 
      <validation-observer ref="registerForm">
        <b-form @submit.prevent="validationForm">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="register-name">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-input
                    id="register-name"
                    v-model="dataForm.name"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="E-mail" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" rules="required">
                  <b-form-input
                    id="register-email"
                    v-model="dataForm.emailAddress"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Telefone" label-for="register-phoneNumber">
                    <validation-provider #default="{ errors }" name="Telefone" rules="required">
                      <b-form-input
                        id="register-phoneNumber"
                        v-model="dataForm.phoneNumber"
                        v-mask="['(##) #####-####']"
                        :state="errors.length > 0 ? false : null"
                        placeholder="(21) 99999-9999" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Permissão"
                label-for="role">
                <validation-provider
                  #default="{ errors }"
                  name="Permissão"
                  rules="required">
                  <b-form-select
                    id="role"
                    v-model="roleSelected"
                    :options="roleOption" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Senha" label-for="Senha">
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="Password"
                  rules="required|password">
                  <b-form-input
                    id="password"
                    v-model="dataForm.password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Confirmar Senha" label-for="c-password">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Senha"
                  rules="required|confirmed:Password">
                  <b-form-input
                    id="c-password"
                    v-model="dataForm.passwordCon"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirmar Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row class="pt-1 justify-content-end">
        <b-col sm="2">
          <b-button class="btn-block" variant="primary" @click="$refs.modalCreate.hide()">Voltar</b-button>
        </b-col>
        <b-col sm="2">
          <b-button class="btn-block" variant="primary" @click="validateForm()">Salvar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BAvatar, BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from './codeTable'
import CreateUser from '@/views/admin/CreateUser.vue'
import UserTipoEnum from '@/enums/UserTipoEnum'
import UserSitutationEnum from '@/enums/UserSitutationEnum'
import { required } from '@validations'
import AccountService from '@/services/AccountService'
import toast from '@/mixins/toast'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    BInputGroupText, 
    BInputGroupAppend,
    BInputGroup,
    BPagination,
    BFormSelect,
    BCard,
    BCollapse,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
    BIcon,
    BFormGroup, 
    BRow, 
    BCol,
    BFormInput,
    CreateUser,
    BAvatar,
    ValidationProvider, 
    ValidationObserver,
    Loader
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-toggle': VBToggle
  },
  mixins: [togglePasswordVisibility, toast],
  data() {
    return {
      loading: false,
      userDelete: {},
      filters:{},
      pageLength: 10,
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'Nome completo / Razão social',
          field: 'name',
        },
        // {
        //   label: 'Email',
        //   field: 'emailAddress',
        // },
        {
          label: 'Telefone',
          field: 'phoneNumber',
        },
        {
          label: 'Funções',
          field: 'roles',
        },
        {
          label: 'Status',
          field: 'isActive',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Analise',
          2: 'Ativo',
          3: 'Rejeitado',
          4: 'Bloqueado',
          5: 'Suspenso',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      listTipo: UserTipoEnum.listEnum,
      listStatus: UserSitutationEnum.listStatusEnum,
      passwordToggleIcon: null,
      roleSelected: null,
      required,
      dataForm: {
        termsOfUse: true,
      },
      roleOption: [
        { value: 'ADMIN', text: 'Administrador' },
        { value: 'MARKETING', text: 'Marketing' },
      ],
    }
  },
  methods: {
    saveUser(){
      this.getList()
    },
    async getList(){
      this.loading = true
      let parans = this.getFilters()
      const responseUser = await this.$http.post(
        '/api/services/app/User/GetUsers',
        parans,
      )
      this.row = responseUser.data.result.items
      const dataResUser = this.row
      for (let i = 0; i < dataResUser.length; i++) {
        const rolesName = dataResUser[i].roles
        let txtRolesName = ''
        for (const x in rolesName) {
          txtRolesName += `${rolesName[x].roleName} `
        }

        dataResUser[i].isActive = dataResUser[i].isActive === true ? 'Ativo' : 'Analise'
        dataResUser[i].roles = txtRolesName.trim().replace(' ', ' - ')
      }

      this.rows = dataResUser
      this.loading = false
    },

    getFilters(){
      let filters = { maxResultCount: 1000 }

      if(this.filters.tipoFilter !== undefined) {
        filters.role = this.filters.tipoFilter
      }
      if(this.filters.statusFilter !== undefined) {
        filters.isActive = this.filters.statusFilter
      }
      if(this.filters.nameFilter !== undefined) {
        filters.nameFilter = this.filters.nameFilter
      }
      if(this.filters.phoneNumberFilter !== undefined) {
        filters.phoneNumberFilter = this.filters.phoneNumberFilter
      }
      return filters
    },

    clear(){
      this.filters = {}
      this.getList()
    },

    confirmDeleteUser(user){
      this.userDelete = user
      this.$bvModal.show('modal-alert-signatario')
    },

    async deleteUser(){
      this.loading = true
      const responseUser = await this.$http.delete('/api/services/app/User/DeleteUser?id='+this.userDelete.id)
      this.getList()
    },

    showModal(){
      this.$refs.modalCreate.show()
    },

    async validateForm(){
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.dataForm.role = this.roleSelected
          this.dataForm.userName = this.dataForm.emailAddress
          this.dataForm.typePerson =  'F'
          this.dataForm.surname = this.dataForm.name.split(' ')[1]
          if(this.dataForm.surname) {
            this.save()
          } else {            
            this.msgError('Insira um nome completo')
          }
        }
      })
    },

    async save(){
      try {
        this.loading = true
        await AccountService.registerAdm(this.dataForm)
        this.loading = false
        this.msgSuccess('Usuário salvo com sucesso')
        this.$refs.modalCreate.hide()
        this.$emit('save')
      } catch(error){
        this.loading = false
        this.msgError(error.response.data.error.message)
      }
    },

  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Analise: 'light-primary',
        Ativo: 'light-success',
        Rejeitado: 'light-danger',
        Bloqueado: 'light-warning',
        Suspenso: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  async created() {
    this.getList()
  },

}
</script>
<style>
 #table_users tr {
  border: 0;
  display: block;
  margin: 15px 8px;
  border: 1px #fff solid;
  border-radius: 10px;
}

 #table_users td {
  padding: 5px;
}
 #table_users tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

 #table_users  .vgt-table.bordered td,  #table_users .vgt-table.bordered th {
  border: #fff !important;
}

 #table_users table.vgt-table td {
   padding: 0.75em 0.75em 0.75em 0.75em !important;
 }

 #table_users .vgt-left-align {
   text-align: left !important;
 }

 #table_users table.vgt-table td {
   vertical-align: baseline !important;
   color: #6e6b7b;
 }
 #table_users table.vgt-table thead {
   display: none;
 }
 #table_users table.vgt-table tbody {
   font-size: 13px;
 }
 #table_users table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
 }
 #table_users table tbody td { max-width: 285px; min-width: 285px; }
 #table_users table tbody td:first-child { max-width: 300px; min-width: 300px; }
 #table_users table tbody td:nth-child(2) { max-width: 150px; min-width: 150px; }
 #table_users  table tbody td:nth-child(3) { max-width: 280px; min-width: 280px; }
 #table_users table tbody td:nth-child(4) { max-width: 80px; min-width: 80px; }
 #table_users table tbody td:nth-child(5) { max-width: 80px; min-width: 80px; float: right; margin-top: -50px; }
 #table_users a { color: #828282; }
 #table_users a:hover { color: #F8AC18; }

 #table_users td[colspan="6"] {
   text-align: center;
   display: inline;
   white-space: nowrap;
   overflow: hidden;
   width: 100%;
 }
.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}

</style>

<template>
  <div>
    <Loader v-if="loading"/>
    <b-button variant="success" @click="showModal()" style="margin-right: 1em">Criar Usuário</b-button>
    <b-modal id="modalCreate"  ref="modalCreate" size="lg"> 
      <validation-observer ref="registerForm">
        <b-form @submit.prevent="validationForm">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="register-name">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-input
                    id="register-name"
                    v-model="dataForm.name"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="E-mail" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" rules="required">
                  <b-form-input
                    id="register-email"
                    v-model="dataForm.emailAddress"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Telefone" label-for="register-phoneNumber">
                    <validation-provider #default="{ errors }" name="Telefone" rules="required">
                      <b-form-input
                        id="register-phoneNumber"
                        v-model="dataForm.phoneNumber"
                        v-mask="['(##) #####-####']"
                        :state="errors.length > 0 ? false : null"
                        placeholder="(21) 99999-9999" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Permissão"
                label-for="role">
                <validation-provider
                  #default="{ errors }"
                  name="Permissão"
                  rules="required">
                  <b-form-select
                    id="role"
                    v-model="roleSelected"
                    :options="roleOption" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Senha" label-for="Senha">
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="Password"
                  rules="required|password">
                  <b-form-input
                    id="password"
                    v-model="dataForm.password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Confirmar Senha" label-for="c-password">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Senha"
                  rules="required|confirmed:Password">
                  <b-form-input
                    id="c-password"
                    v-model="dataForm.passwordCon"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirmar Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row class="pt-1 justify-content-end">
        <b-col sm="2">
          <b-button class="btn-block" variant="primary" @click="$refs.modalCreate.hide()">Voltar</b-button>
        </b-col>
        <b-col sm="2">
          <b-button class="btn-block" variant="primary" @click="validateForm()">Salvar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormInput, BForm, BFormGroup, BFormSelect, BModal, BButton, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { required } from '@validations'
import toast from '@/mixins/toast'
import Loader from '@/components/Loader.vue'
import AccountService from '@/services/AccountService'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BForm,
    BInputGroup, BInputGroupAppend,
    BModal,
    BButton,
    Loader,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  data(){
    return {
      loading: false,
      passwordToggleIcon: null,
        roleSelected: null,
        required,
        dataForm: {
          termsOfUse: true,
        },
      roleOption: [
        { value: 'ADMIN', text: 'Administrador' },
        { value: 'MARKETING', text: 'Marketing' },
      ],
    }
  },
  mixins: [togglePasswordVisibility, toast],
  methods: {
    async validateForm(){
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.dataForm.role = this.roleSelected
          this.dataForm.userName = this.dataForm.emailAddress
          this.dataForm.typePerson =  'F'
          this.dataForm.surname = this.dataForm.name.split(' ')[1]
          if(this.dataForm.surname) {
            this.save()
          } else {            
            this.msgError('Insira um nome completo')
          }
        }
      })
    },

    async save(){
      try {
        this.loading = true
        await AccountService.registerAdm(this.dataForm)
        this.loading = false
        this.msgSuccess('Usuário salvo com sucesso')
        this.$refs.modalCreate.hide()
        this.$emit('save')
      } catch(error){
        this.loading = false
        this.msgError(error.response.data.error.message)
      }
    },
    
    showModal(){
      this.$refs.modalCreate.show()
    },
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body {
    background-color: #f8f8f8 !important;
}
#modalCreate .modal-footer{
  display: none;
}
</style>
